import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Page404 from 'gatsby-theme-dettol/src/templates/NotFoundPage/NotFoundPage';
import 'styles/main.scss';
import 'gatsby-theme-dettol/src/pages/404.scss';

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query Page404QueryZH {
      languageSelector(lang: { eq: "zh-hk" }) {
        ...FragmentLanguageSwitcher
      }
      siteSettings(lang: { eq: "zh-hk" }) {
        ...FragmentSiteSettings
      }
      menu(lang: { eq: "zh-hk" }) {
        ...FragmentHeader
      }
      footerNavigation(lang: { eq: "zh-hk" }) {
        ...FragmentFooter
      }
      mobileAppPromoBanner(lang: { eq: "zh-hk" }) {
        ...FragmentMobileAppPromoBanner
      }
      homepageSettings(lang: { eq: "zh-hk" }) {
        ...FragmentHomepageSettings
      }
      pageNotFound(lang: { eq: "zh-hk" }) {
        ...FragmentNotFoundPageProps
      }
    }
  `);

  return <Page404 data={data} />;
};

export default NotFoundPage;
